.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  z-index: 1;
  width: 100%;
  display: none;
}

.owl-carousel .owl-stage {
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
  min-height: 1px;
}

.owl-carousel .owl-item img {
  width: 100%;
  display: block;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  padding: 0 !important;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  background: url("owl.video.play.54c2f35c.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: transform .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}
/*# sourceMappingURL=index.33c4f984.css.map */
